.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 12vh;
  max-height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h3 {
  margin-bottom: 1%;
}